<template>
<b-card class="p-2">
    <b-row >
        <div>
            <h6>Virtual Fence Infomation</h6>
            <div class=" mb-1">🎫 {{ $t('Name')}}: <strong>{{name}}</strong></div>
            <div v-if="maxSpeed" class="mb-1">🚗 {{ $t('Max Speed')}}: <strong>{{maxSpeed}}</strong></div>
            <div class="mb-1">🔔 {{ $t('Alert In')}}: <strong>{{alertIn ? $t('Yes') : $t('No')}}</strong></div>
            <div class="">🔔 {{ $t('Alert Out')}}: <strong>{{alertOut ? $t('Yes') : $t('No')}}</strong></div>

        </div>
    </b-row>
    <b-row class="mt-2">
        <b-col col="12" class="text-center">
            <b-button-group size="sm">
                <b-button variant="primary" :pressed="btnMap" @click="edit()">
                    {{ $t('Edit')}}
                </b-button>
                <b-button variant="danger" :pressed="btnGround" @click="del()">
                    {{ $t('Delete')}}
                </b-button>
            </b-button-group>

        </b-col>
    </b-row>
</b-card>
</template>

    
<script>
import {
    BCard,
    BCardText,
    BRow,
    BCol,
    BBadge,
    BButtonGroup,
    BButton
} from 'bootstrap-vue'
import router from '@/router'


export default {
    components: {
        BCard,
        BCardText,
        BRow,
        BCol,
        BBadge,
        BButtonGroup,
        BButton
        //SimpleIndicatorCard
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        myId: {
            type: String,
            required: true,
        },
        maxSpeed: {
            type: String,
            required: false,
        },
        alertIn: {
            type: Boolean,
            required: true,
        },
        alertOut: {
            type: Boolean,
            required: true,
        },
        isFence: {
            type: Boolean,
            required: true,
        },

    },
    methods: {
        del(){
          this.isFence ?  this.$emit('event-delete-fence') : this.$emit('event-delete-point')
        },
        edit(){
            this.isFence ?  this.$emit('event-to-fence') : this.$emit('event-to-point')
        }
    },

}
</script>

    
<style>

    </style>
