<template>
    <b-row>
        <b-col cols="12">
           
            <!--Filters-->
            <transition name="fade" mode="out-in">
                <b-card key="3" v-if="showFilters" no-body class="mb-2 ">
                    <b-card-header class="d-flex justify-content-between pl-1 pt-1">
                        <h5>
                            {{$t('Filters')}}
                        </h5>
                        <b-avatar @click="showFilters = false" v-b-tooltip.hover :title="titleTootipBtnCloseFilter" class="mr-1" button variant="danger" size="32px">
                            <feather-icon icon="XIcon" />
                        </b-avatar>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="12" md="4">
                                <h5 class="font-weight-bold">{{$t('Search')}}</h5>
                                <b-form-input v-model="searchQuery" :placeholder="$t('Search ...')" />
                            </b-col>
                            <b-col cols="12" md="4" class="mb-md-0 mb-2">
                                <h5 class="font-weight-bold">{{$t('Owner')}}</h5>
                                <vue-autosuggest v-model="searchOwner" :suggestions="filteredOptions" :input-props="inputProps" @selected="selectHandler" @input="onInputChange">
                                    <template slot-scope="{suggestion}">
                                        <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                                        <div v-show="suggestion.item.ownerName">
                                          <span class="text-muted">{{ $t('Belongs to: ') }}</span>
                                          <span class="font-weight-bold"> {{ suggestion.item.ownerName }} </span>
                                        </div>
                                    </template>
                                </vue-autosuggest>
                            </b-col>
    
                            <b-col cols="12" md="4">
                                <h5 class="font-weight-bold">{{$t('Status')}}</h5>
                                <multiselect v-model="chosenStatus" :options="options" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :placeholder="placeholderSelect" label="description" track-by="description" :preselect-first="false">
                                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} &nbsp; {{$t('options selected')}} </span></template>
                                </multiselect>
                            </b-col>
    
                        </b-row>
    
                    </b-card-body>
                </b-card>
    
            </transition>
        </b-col>
    
        <map-customizer />
        <b-col cols="12" class="styleMap" :class="selectedNick ? '' : 'hideNicks'">
            <l-map :center="center" ref="myMap" :zoom="zoom" @ready="mapReady" :key="mapKey">
                <l-tile-layer :url="chooseUrl"></l-tile-layer>
                <l-control position="topleft">
                    <b-avatar v-if="!showFilters" @click="showFilters = true" button rounded="sm" variant="primary" size="27px" v-b-tooltip.hover :title="titleTootipBtnFilter">
                        <feather-icon icon="FilterIcon" />
                    </b-avatar>
                </l-control>
    
                <l-marker ref="pins" v-for="(asset, i) in assetsData" v-bind="asset.id" v-if="asset.lastLat && asset.lastLat != 0 && asset.lastLng && asset.lastLng != 0" :lat-lng="[asset.lastLat, asset.lastLng]" :key='i'>
                    <l-icon :class-name="`someExtraClass asset-${asset.lastWorkstatus}`" :icon-size="dynamicSize" :tooltip-anchor="dynamicTooltip" :popup-anchor="dynamicPopup" :icon-anchor="dynamicAnchor">
                        <div :class="{'pulsar': asset.pan == true}">
                            <img v-if="selectedIcon" class="with-icon" :src="resolveIcon(asset.assetType, asset.iconUrl)">
                            <img v-else class="no-icon" :src="require('@/assets/images/assets/img_blank.png')">
                        </div>

                    </l-icon>
                    <l-popup :id="`pop${i}`" class="popup-map">
                        <b-row>
                            <b-col col="12">
                                <card-info :lastConnection="`${ formatDate(asset.lastConnectionTime) }`" :customerName="asset.customerName" :model="`${asset.model} - ${asset.modelYear}`" :chassis="`${asset.plate ? asset.plate : asset.chassisNumber}`" :status="`${asset.lastWorkstatus}`" :urlImage="resolveIcon(asset.assetType, asset.iconUrl)" />
                                
                            </b-col>
    
                        </b-row>
                        <b-row>
                            <b-col col="12" class="text-center">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info" size="sm" @click="$router.push({ name: 'preview-asset', params: { id: asset.id }})">
                                    {{ $t('Open') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </l-popup>
    
                    <l-tooltip v-show="selectedNick" :options="{ permanent: selectedNick, interactive: false }">
                        <div>
                            {{ asset.plate != '' ? asset.plate : asset.chassisNumber }} - Vel: <strong>{{asset.lastkmh}} Km/h</strong>
                        </div>
                    </l-tooltip>
                </l-marker>
                <l-polygon v-if="polygonFence && polygonFence.length > 0" v-for="(polygon, i) in polygonFence" :lat-lngs="polygon.points" :key="polygon.id">
                    <l-popup>
                        <b-row>
                            <b-col cols="12">
                                <popup-fence :isFence="true" @event-to-fence="toFence(polygon.id)" @event-delete-fence="confirmDelete(polygon.id, true)" :myId="polygon.id" :name="polygon.name" :maxSpeed="polygon.maxSpeed" :alertIn="polygon.alertIn" :alertOut="polygon.alertOut" />
                            </b-col>
                        </b-row>
    
                    </l-popup>
                </l-polygon>
                <l-circle v-if="circleFence && circleFence.length > 0" v-for="(circle, i) in circleFence" :lat-lng="[circle.lat, circle.lng]" :radius="circle.radius" :key="circle.id">
                    <l-popup>
                        <b-row>
                            <b-col cols="12">
                                <popup-fence :isFence="true" @event-to-fence="toFence(circle.id)" @event-delete-fence="confirmDelete(circle.id, true)" :myId="circle.id" :name="circle.name" :maxSpeed="circle.maxSpeed" :alertIn="circle.alertIn" :alertOut="circle.alertOut" />
                            </b-col>
                        </b-row>
                    </l-popup>
                </l-circle>
                <l-circle v-if="circlePoint && circlePoint.length > 0" v-for="(circle, i) in circlePoint" :lat-lng="[circle.lat, circle.lng]" :radius="circle.radius" :key="circle.id" color="#9c27b0" fillColor="#9c27b0">
                    <l-popup>
                        <b-row>
                            <b-col cols="12">
                                <popup-fence :isFence="false" @event-to-point="toPoint(circle.id)" @event-delete-point="confirmDelete(circle.id, false)" :myId="circle.id" :name="circle.name" :alertIn="circle.alertIn" :alertOut="circle.alertOut" />
                            </b-col>
                        </b-row>
                    </l-popup>
                </l-circle>
            </l-map>
    
        </b-col>
    </b-row>
    </template>
    
    <script>
    import {
        BRow,
        BCol,
        BCard,
        BButton,
        BAvatar,
        BCardHeader,
        BCardBody,
        BFormInput
    } from "bootstrap-vue";
    
    import {
        LMap,
        LTileLayer,
        LMarker,
        LIcon,
        LPopup,
        LTooltip,
        LPolygon,
        LCircle,
        LControl
    } from "vue2-leaflet";
    import "leaflet/dist/leaflet.css";
    import {
        latLng,
        icon,
        Icon,
        latLngBounds,
        featureGroup
    } from "leaflet";
    import L from "leaflet";
    
    import MapCustomizer from "@/views/main/components/MapCustomizer.vue";
    import PopupFence from "@/views/main/components/PopupFence.vue";
    
    import CardInfo from "@/views/main/asset/asset-preview/indicators/CardInfo.vue";
    
    
    import assetStoreModule from "./asset/assetStoreModule";
    
    import store from "@/store";
    import router from '@/router'
    
    import {
        ref,
        watch
    } from "@vue/composition-api";
    import {
        setTimeout
    } from "timers";
    
    import {
        VueAutosuggest
    } from 'vue-autosuggest'
    import Multiselect from 'vue-multiselect'
    
    export default {
        components: {
            BRow,
            BCol,
    
            LMap,
            LTileLayer,
            LMarker,
            LIcon,
            LPopup,
            LTooltip,
            LPolygon,
            LCircle,
            LControl,
    
            MapCustomizer,
            PopupFence,
    
            CardInfo,
           
            BButton,
            BCard,
            BAvatar,
            BCardHeader,
            BCardBody,
            BFormInput,
    
            VueAutosuggest,
            Multiselect
        },
        data() {
            return {
                //selectedNick: false,
                //selectedIcon: false,
                //selectedFitMap: false,
                //selectedLayer: "map",

                isPanic: false,
    
                emptyText: this.$t('No matching records found'),
                showFilters: false,
                titleTootipBtnFilter: this.$t('Open Filters'),
                titleTootipBtnCloseFilter: this.$t('Close Filters'),
    
                selectedNick: localStorage.selectedNick == "true" ? true : false,
                selectedIcon: localStorage.selectedIcon == "true" ? true : false,
                selectedFitMap: localStorage.selectedFitMap == "true" ? true : false,
                selectedLayer: localStorage.selectedLayer != undefined ? localStorage.selectedLayer : "map",
    
                mapKey: 1,
    
                assetsData: [],
                url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                zoom: 5,
                center: [-12.4131269, -55.5261856],
                icon: icon({
                    //iconUrl: this.chooseIcon,
                    iconSize: [32, 37]
                    //iconAnchor: [16, 37] -12.4131269,-55.5261856,5z
                }),
    
                customText: "Foobar",
                iconSize: 40,
    
                sizeMap: "height: 75vh",
                toltipAnchor: [0, 0],
                popupAnchor: [27, 0],
                staticAnchor: [27, 30],
    
                markers: [{
                        latLong: [-19.9613815, -44.0516314],
                        plate: "5EWHE21JX24060955",
                        status: "on"
                    },
                    {
                        latLong: [-19.9590812, -44.0392844],
                        plate: "HTP 7865",
                        status: "off"
                    },
                    {
                        latLong: [-19.9573424, -44.0442605],
                        plate: "JBN 4574",
                        status: "failure"
                    }
                ],
    
                assetPage: 1,
    
                simpleFence: [],
                circleFence: [],
                polygonFence: [],
    
                simplePoint: [],
                circlePoint: [],
    
                searchOwner: '',
                datasuggest: [],
                filteredOptions: [],
                inputProps: {
                    id: 'autosuggest__input',
                    class: 'form-control',
                    placeholder: "Search owner ...",
                },
                limit: 3,
                selected: null,
                customerId: '',
    
                chosenStatus: [],
                options: [],
                searchQuery:''
    
            };
        },
    
        methods: {
    
            fetchTypesWorkStatus() {
                store
                    .dispatch(`app-asset/fetchTypesWorkStatus`)
                    .then(response => {
                        this.options = response.data.data
    
                    })
                    .catch(() => {
                        console.log('erro')
                    })
            },
    
            selectHandler(option) {
                this.selected = option.item
                this.filteredOptions = []
                this.searchOwner = option.item.name
                this.customerId = option.item.id
    
                console.log(`option selecionado `, this.customerId)
            },
    
            onInputChange(text) {
                if (text === '' || text === undefined || text.length < 3) {
                    this.customerId = ''
                    return
                }
    
                store.dispatch('app-asset/fetchCustomers', {
                        q: this.searchOwner,
    
                    })
                    .then(response => {
    
                        this.filteredOptions = [{
                            data: response.data.data,
                        }]
    
                    })
                    .catch(error => {
    
                        console.log(error)
    
                    })
            },
    
            toFence(myId) {
                router.push({
                    name: 'fence-register',
                    params: {
                        id: myId
                    }
                })
            },
            toPoint(myId) {
                router.push({
                    name: 'point-register',
                    params: {
                        id: myId
                    }
                })
            },
    
            confirmDelete(myId, isFence) {
                this.$swal({
                    title: this.$t('Are you sure?'),
                    text: this.$t("You can't revert your action"),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('No'),
                    showCloseButton: true,
                    showLoaderOnConfirm: true,
                    customClass: {
                        cancelButton: 'cancelBtn',
                        confirmButton: 'mr-1',
                    }
                }).then((result) => {
                    if (result.value) {
                        console.log('ID', myId)
                        isFence ? this.deleteFence(myId) : this.deletePoint(myId)
    
                    } else {}
                })
            },
    
            async deletePoint(myId) {
    
                await store.dispatch('app-asset/deletePoint', {
                        id: myId
                    })
                    .then(response => {
                        this.$swal(this.$t("Deleted"), `${this.$t("You successfully deleted")}`, 'success')
                            .then((result) => {
                                if (result.value) {
    
                                    let position = this.circlePoint.indexOf(this.circlePoint.find(e => e.id == myId))
                                    if (position >= 0) {
                                        //position = this.polygonFence.indexOf(this.polygonFence.find(e => e.id == myId))
                                        const itemRemoved = this.circlePoint.splice(position, 1);
                                    }
    
                                } else {}
                            })
    
                    })
                    .catch(error => {
                        this.$swal('Error', 'Your Point Of Interest is still intact', 'error')
                        console.log(error);
                    })
            },
    
            async deleteFence(myId) {
    
                await store.dispatch('app-asset/deleteFence', {
                        id: myId
                    })
                    .then(response => {
                        this.$swal(this.$t("Deleted"), `${this.$t("You successfully deleted")}`, 'success')
                            .then((result) => {
                                if (result.value) {
    
                                    //this.circleFence=[]
                                    //this.polygonFence=[]
                                    //this.fetchFences(1)
                                    let position = this.circleFence.indexOf(this.circleFence.find(e => e.id == myId))
                                    if (position >= 0) {
                                        //position = this.polygonFence.indexOf(this.polygonFence.find(e => e.id == myId))
                                        const itemRemoved = this.circleFence.splice(position, 1);
                                    } else {
                                        position = this.polygonFence.indexOf(this.polygonFence.find(e => e.id == myId))
                                        const itemRemoved = this.polygonFence.splice(position, 1);
                                    }
    
                                } else {}
                            })
    
                    })
                    .catch(error => {
                        this.$swal('Error', 'Your asset is still intact', 'error')
                        console.log(error);
                    })
            },
    
            mapReady() {
                console.log("mapReady");
    
            },
    
            fetchFence(id) {
                store.dispatch('app-asset/fetchFence', {
                        //id: router.currentRoute.params.id
                        id: id
                    })
                    .then(response => {
                        if (response.data.virtualFenceGeometryId == 1) {
                            this.circleFence.push(response.data)
                        }
                        if (response.data.virtualFenceGeometryId == 2) {
                            /*
                            let fence = response.data;
                            fence.points.sort( (a, b) => {
                                return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
                            });
                            */
                            this.polygonFence.push(response.data)
                        }
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
    
            fetchPoint(id) {
                store.dispatch('app-asset/fetchPoint', {
                        //id: router.currentRoute.params.id
                        id: id
                    })
                    .then(response => {
                        this.circlePoint.push(response.data)
    
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
    
            fetchFences(page) {
                store
                    .dispatch("app-asset/fetchFences", {
                        q: ``,
                        perPage: 100,
                        page: page,
                        sortBy: "id",
                        sortDesc: false,
                        status: ""
                    })
                    .then(response => {
    
                        if (response.data.data && response.data.data.length > 0) {
                            response.data.data.map((node, i) => {
                                this.simpleFence.push(node)
                            });
                            page++
                            this.fetchFences(page)
                        } else {
    
                            this.simpleFence.map((node, i) => {
                                this.fetchFence(node.id)
                            });
                        }
    
                    })
                    .catch((e) => {
                        console.log(e);
    
                    })
            },
            fetchPoints(page) {
                store
                    .dispatch("app-asset/fetchPoints", {
                        q: ``,
                        perPage: 100,
                        page: page,
                        sortBy: "id",
                        sortDesc: false,
                        status: ""
                    })
                    .then(response => {
    
                        if (response.data.data && response.data.data.length > 0) {
                            response.data.data.map((node, i) => {
                                this.simplePoint.push(node)
                            });
                            page++
                            this.fetchPoints(page)
                        } else {
    
                            this.simplePoint.map((node, i) => {
                                this.fetchPoint(node.id)
                            });
                        }
    
                    })
                    .catch((e) => {
                        console.log(e);
    
                    })
            },
    
            populateAssets(page) {
    
                //this.assetsData = []
                //var page = 1
    
                store
                    .dispatch("app-asset/fetchAssets", {
                        q: this.searchQuery,
                        customerId: this.customerId,
                        workStatusList: this.chosenStatus,
                        perPage: 100,
                        page: page
                    })
                    .then(response => {
    
                        if (response.data.data && response.data.data.length > 0) {
                            response.data.data.map((node, i) => {
    
                                this.assetsData.push(node)
    
                            });
                            page++
                            this.populateAssets(page)
                        } else {
    
                            this.$refs.myMap.mapObject.fitBounds(
                                this.assetsData.map(m => {
                                    if (m.lastLat && m.lastLat != 0 && m.lastLng && m.lastLng != 0)
                                        return [m.lastLat, m.lastLng];
                                    return;
                                })
                            );
                        }
    
                    })
                    .catch((e) => {
                        console.log(e);
    
                    })
    
            },
    
            formatDate(myDate) {
                const d = new Date(myDate);
                let dt = d.toLocaleDateString();
    
                return `${dt} - ${d.getHours()}:${d.getMinutes()}`;
            },
    
            event_tlm: function (content) {
                
                var panic = {
                    assetId: content.assetId,
                    pan: content.data.pan,
                }

                var asset = {
                    id: content.assetId,
                    lastLat: content.lat,
                    lastLng: content.lng,
                    lastKmh: content.kmh,
                    lastPositionTime: content.deviceDateTime,
                    lastWorkstatus: content.workStatus
                };

                                
                var panic = {
                    assetId: content.assetId,
                    pan: content.data.pan,
                }

                console.log("Panic", panic.pan);

                this.realtimeUpdateAsset(asset);
                this.realtimeUpdatePanic(panic);
            },

            realtimeUpdatePanic(panic){
                if(panic == null || panic.assetId == undefined || panic.assetId == null || panic.pan == undefined || panic.pan == null) return

                var self = this;
                for (var i = 0; i < self.assetsData.length; i++) {
                    if (self.assetsData[i].id == panic.assetId) {
                        this.assetsData[i].pan = panic.pan;
                        console.log("Panic ID this.assetsData[i].pan = ", this.assetsData[i].pan);
                        this.$forceUpdate()
                    }
                }
            },
    
            registerEventBusListeners: function () {
                this.$root.$on("tlm", this.event_tlm);
            },
    
            deregisterEventBusListeners: function () {
                this.$root.$off("tlm", this.event_tlm);
            },
    
            realtimeUpdateAsset: function (asset) {
                if (
                    asset == null ||
                    asset.lastLat == null ||
                    asset.lastLng == null ||
                    asset.lastKmh == null ||
                    asset.lastWorkstatus == null
                )
                    return;
    
                var self = this;
                for (var i = 0; i < self.assetsData.length; i++) {
                    if (self.assetsData[i].id == asset.id) {
                        self.assetsData[i].lastLat = asset.lastLat;
                        self.assetsData[i].lastLng = asset.lastLng;
                        self.assetsData[i].lastkmh = asset.lastKmh;
                        self.assetsData[i].lastPositionTime = asset.lastPositionTime;
                        self.assetsData[i].lastWorkstatus = asset.lastWorkstatus;
    
                        if (self.selectedFitMap == true) {
                            this.$refs.myMap.mapObject.flyToBounds(
                                this.assetsData.map(m => {
                                    if (m.lastLat && m.lastLat != 0 && m.lastLng && m.lastLng != 0)
                                        return [m.lastLat, m.lastLng];
                                    return;
                                })
                            );
                        }
                    }
                }
            },
    
            resolveIcon: (assetType, iconUrl) => {
                if (iconUrl) return iconUrl;
                if (assetType === 1) return require("@/assets/images/assets/Car.png");
                if (assetType === 2)
                    return require("@/assets/images/assets/Motorcycle.png");
                if (assetType === 3) return require("@/assets/images/assets/Truck.png");
                if (assetType === 4)
                    return require("@/assets/images/assets/TruckTractor.png");
                if (assetType === 5) return require("@/assets/images/assets/Van.png");
                if (assetType === 6) return require("@/assets/images/assets/Bus.png");
                if (assetType === 7) return require("@/assets/images/assets/Pickup.png");
                if (assetType === 8)
                    return require("@/assets/images/assets/Excavator.png");
                if (assetType === 9) return require("@/assets/images/assets/Grader.png");
                if (assetType === 10)
                    return require("@/assets/images/assets/WheelLoader.png");
                if (assetType === 11) return require("@/assets/images/assets/Dozer.png");
                if (assetType === 12)
                    return require("@/assets/images/assets/BackhoeLoader.png");
                if (assetType === 13)
                    return require("@/assets/images/assets/SkidSteerLoader.png");
                if (assetType === 14) return require("@/assets/images/assets/Boat.png");
                if (assetType === 15) return require("@/assets/images/assets/JetSki.png");
                if (assetType === 16)
                    return require("@/assets/images/assets/BoxTruck.png");
                if (assetType === 17)
                    return require("@/assets/images/assets/MixerTruck.png");
                if (assetType === 18)
                    return require("@/assets/images/assets/WaterTruck.png");
                if (assetType === 19) return require("@/assets/images/assets/CraneTruck.png");
                if (assetType === 20) return require("@/assets/images/assets/RoadRoller.png");
                if (assetType === 21) return require("@/assets/images/assets/ForkLift.png");
                if (assetType === 22) return require("@/assets/images/assets/ScissorLift.png");
                if (assetType === 23) return require("@/assets/images/assets/ForkLiftCabin.png");
                if (assetType === 24) return require("@/assets/images/assets/ContainerLifter.png");
                if (assetType === 25) return require("@/assets/images/assets/ArticulatedLift.png");
                if (assetType === 26) return require("@/assets/images/assets/CombineHarvester.png");
                if (assetType === 27) return require("@/assets/images/assets/SealcoatTruck.png");
                if (assetType === 28) return require("@/assets/images/assets/IrrigationPivot.png");
                if (assetType === 29) return require("@/assets/images/assets/SecurityHelmet.png");
                if (assetType === 30) return require("@/assets/images/assets/CropSprayer.png");
                if (assetType === 36) return require("@/assets/images/assets/StationaryEngine.png");
                if (assetType === 37) return require("@/assets/images/assets/DumpTruck.png");
                return require("@/assets/images/assets/Car.png");
            }
        },
        beforeCreate() {},
        created() {
            const ASSET_APP_STORE_MODULE_NAME = "app-asset";
    
            // Register module
            if (!store.hasModule(ASSET_APP_STORE_MODULE_NAME))
                store.registerModule(ASSET_APP_STORE_MODULE_NAME, assetStoreModule);
    
        },
        mounted() {
    
            this.registerEventBusListeners();
    
            this.mapKey = this.mapKey++; //IMPORTANT
    
            this.populateAssets(1);
    
            this.fetchFences(1)
    
            this.fetchPoints(1)
    
            this.fetchTypesWorkStatus()
    
            //this.drawCircle()
    
        },
        beforeDestroy() {
            this.deregisterEventBusListeners();
        },
        destroyed() {
            const ASSET_APP_STORE_MODULE_NAME = "app-asset";
            if (store.hasModule(ASSET_APP_STORE_MODULE_NAME))
                store.unregisterModule(ASSET_APP_STORE_MODULE_NAME);
        },
        watch: {
            selectedNick(newVal) {
                localStorage.selectedNick = newVal;
            },
            selectedIcon(newVal) {
                localStorage.selectedIcon = newVal;
            },
            selectedLayer(newVal) {
                localStorage.selectedLayer = newVal;
            },
            selectedFitMap(newVal) {
                localStorage.selectedFitMap = newVal;
            },
            customerId(newVal) {
                this.assetsData = []
                this.populateAssets(1)
            },
            chosenStatus(newVal) {
                this.assetsData = []
                this.populateAssets(1)
            },
            searchQuery(newVal) {
                
                this.assetsData = []
                this.populateAssets(1)
    
            }
        },
        computed: {
            dynamicSize() {
                this.toltipAnchor = [27, 30];
                return [this.iconSize, this.iconSize * 1.15];
            },
            dynamicAnchor() {
                if (this.selectedIcon == true) {
                    return [27, 30];
                } else {
                    return [15, 13];
                }
            },
            dynamicTooltip() {
                if (this.selectedIcon == true) {
                    return [25, 0];
                } else {
                    return [7, -15];
                }
            },
            dynamicPopup() {
                if (this.selectedIcon == true) {
                    return [2, -27];
                } else {
                    return [-7, -13];
                }
            },
            chooseUrl() {
                if (this.selectedLayer == "map")
                    return "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
                if (this.selectedLayer == "hybrid")
                    //return "https://1.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png8?lg=por&app_id=adPipTPyffSRGrMtXttz&app_code=oNecq1kBuaikCv_pYw20yg";
                    return "https://2.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png8?lg=por&apiKey=U7wvT2IU0rFTeGwMoIpdf7JaQx5QH74SyhG4qiHy7Q8"
                if (this.selectedLayer == "ground")
                    return "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
            },
            chooseIcon() {
                //if (localStorage.selectedIcon != undefined) this.selectedIcon = localStorage.selectedIcon;
                if (this.selectedIcon == true) return true;
                if (this.selectedIcon == false) return false;
            }
        }
    };
    </script>
    
    <style src="vue-multiselect/dist/vue-multiselect.min.css"></style><style lang="scss" scoped>
    .popup-map {
        width: 15rem !important;
    
        min-width: 300px !important;
    
        max-width: 500px !important
    }
    </style><style lang="scss">
    @import "@core/scss/vue/libs/map-leaflet.scss";
    @import '@core/scss/vue/libs/vue-autosuggest.scss';

    @keyframes pulsar {
      0% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
        transform: scale(1);
      }
      70% {
        box-shadow: 0 0 0 15px rgba(255, 0, 0, 0);
        transform: scale(1);
      }
      100% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
        transform: scale(1);
      }
    }

    .pulsar {
      animation: pulsar 1s infinite;
      border-radius: 50%;
    }
    
    .vue2leaflet-map.leaflet-container {
        height: 77vh !important;
    }
    
    .someExtraClass {
        //background-color: #28c76f;
        padding: 4px;
        border-radius: 50px 50px 50px 50px;
        box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);
        text-align: center;
        width: auto !important;
        height: auto !important;
    }
    
    .asset-on {
        background-color: #28c76f !important;
    }
    
    .asset-off {
        background-color: #82868b !important;
    }
    
    .asset-failure {
        background-color: #ef5258 !important;
    }
    
    .asset-noconnection {
        background-color: #f5f4f8 !important;
    }
    
    .asset-severe {
        background-color: #ff9d54 !important;
    }
    
    .asset-iddle {
        background-color: #00cfe8 !important;
    }
    
    .marker-no-icon {
        background-color: red !important;
    }
    
    .no-icon {
        width: 17px;
    }
    
    .with-icon {
        width: 50px;
    }
    
    .hideNicks .leaflet-tooltip {
        display: none;
    }
    
    .btn-card {
        margin-left: 27% !important;
    }
    
    //@media
    @media screen and (min-height: 768px) {
        .vue2leaflet-map.leaflet-container {
            height: 77vh !important;
        }
    }
    
    @media screen and (min-height: 900px) {
        .vue2leaflet-map.leaflet-container {
            height: 82vh !important;
        }
    }
    
    @media screen and (min-height: 1024px) {
        .vue2leaflet-map.leaflet-container {
            height: 84vh !important;
        }
    }
    </style>