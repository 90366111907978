<template>
<div class="customizer d-none d-md-block" :class="{'open': isCustomizerOpen}">
    <!-- Toggler -->
    <b-link class="customizer-toggle d-flex align-items-center justify-content-center" @click="isCustomizerOpen = !isCustomizerOpen">
        <feather-icon icon="SettingsIcon" size="15" class="spinner" />
    </b-link>
    <!-- /Toggler -->

    <!-- Header -->
    <div class="customizer-section d-flex justify-content-between align-items-center">
        <div>
            <h4 class="text-uppercase mb-0">
                {{ $t('Map Customizer')}}
            </h4>
            <small> {{ $t('Customize according to your preferences')}}</small>
        </div>
        <feather-icon icon="XIcon" size="18" class="cursor-pointer" @click="isCustomizerOpen = !isCustomizerOpen" />
    </div>
    <!-- Header -->

    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="ps-customizer-area scroll-area">

        <!-- SECTION: Menu -->
        <div class="customizer-section">

            <!-- Icon Type -->
            <b-form-group class="font-weight-bold" :label="$t('Choose your favorite icon')">
                <b-form-radio-group v-model="selectedIcon" name="icon-type" :options="iconOptions" />
            </b-form-group>

            <!-- Layer Map Type -->
            <b-form-group class="font-weight-bold" :label="$t('Choose map layer')">
                <b-form-radio-group v-model="selectedLayer" name="layer-type" :options="layerOptions" />
            </b-form-group>

            <!-- Nickname Visiblity -->
            <div class="d-flex justify-content-between align-items-center mt-2">
                <span class="font-weight-bold"> {{ $t('Nickname')}}</span>
                <b-form-checkbox v-model="selectedNick" name="is-nick-visible" class="mr-0" switch inline />
            </div>

            <!-- Fit Map -->
            <div class="d-flex justify-content-between align-items-center mt-2">
                <span class="font-weight-bold"> {{ $t('Map Auto Fit')}}</span>
                <b-form-checkbox v-model="selectedFitMap" name="fit-map" class="mr-0" switch inline />
            </div>

        </div>

    </vue-perfect-scrollbar>
</div>
</template>

<script>
import {
    BLink,
    BFormRadioGroup,
    BFormGroup,
    BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useAppCustomizer from '@core/layouts/components/app-customizer/useAppCustomizer.js'

import {
    ref,
    watch
} from '@vue/composition-api'

export default {
    components: {
        // BSV
        BLink,
        BFormRadioGroup,
        BFormCheckbox,
        BFormGroup,

        // 3rd party
        vSelect,
        VuePerfectScrollbar,

    },
    data() {
        return {
            iconOptions: [{
                    text: 'Icon',
                    value: true
                },
                {
                    text: 'Dot',
                    value: false
                },
            ],
            layerOptions: [{
                    text: 'Map',
                    value: 'map'
                },
                {
                    text: 'Hybrid',
                    value: 'hybrid'
                },
                {
                    text: 'Ground',
                    value: 'ground'
                },
            ],

            //selectedNick: false,
            //selectedIcon: false,
            //selectedFitMap: false,
            //selectedLayer: "map",

            selectedNick: localStorage.selectedNick == "true" ? true : false,
            selectedIcon: localStorage.selectedIcon == "true" ? true : false,
            selectedFitMap: localStorage.selectedFitMap == "true" ? true : false,
            selectedLayer: localStorage.selectedLayer != undefined ? localStorage.selectedLayer : "map",

        };

    },
    watch: {
        selectedNick: {
            handler(newVal) {
                this.$parent.selectedNick = newVal;
                localStorage.selectedNick = newVal;
            }
        },
        selectedIcon: {
            handler(newVal) {
                this.$parent.selectedIcon = newVal;
                localStorage.selectedIcon = newVal;
            }
        },
        selectedLayer: {
            handler(newVal) {
                this.$parent.selectedLayer = newVal;
                localStorage.selectedLayer = newVal;
            }
        },
        selectedFitMap: {
            handler(newVal) {
                this.$parent.selectedFitMap = newVal;
                localStorage.selectedFitMap = newVal;
            }
        },

    },
    setup() {

        const {
            // Vertical Menu
            isVerticalMenuCollapsed,

            // Customizer
            isCustomizerOpen,

            // Skin
            skin,
            skinOptions,

            // Content Width
            contentWidth,
            contentWidthOptions,

            // RTL
            isRTL,

            // routerTransition
            routerTransition,
            routerTransitionOptions,

            // Layout Type
            layoutType,
            layoutTypeOptions,

        } = useAppCustomizer()

        if (layoutType.value === 'horizontal') {
            // Remove semi-dark skin option in horizontal layout
            const skinSemiDarkIndex = skinOptions.findIndex(s => s.value === 'semi-dark')
            delete skinOptions[skinSemiDarkIndex]

            // Remove menu hidden radio in horizontal layout => As we already have switch for it
            const menuHiddneIndex = navbarTypes.findIndex(t => t.value === 'hidden')
            delete navbarTypes[menuHiddneIndex]
        }

        // Perfect Scrollbar
        const perfectScrollbarSettings = {
            maxScrollbarLength: 60,
            wheelPropagation: false,
        }

        return {
            // Vertical Menu
            isVerticalMenuCollapsed,

            // Customizer
            isCustomizerOpen,

            layoutTypeOptions,

            // Perfect Scrollbar
            perfectScrollbarSettings,

        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style><style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.customizer-section {
    padding: 1.5rem;
    border-bottom: 1px solid #ebe9f1;

    .dark-layout & {
        border-color: $theme-dark-border-color;
    }

    #skin-radio-group ::v-deep {
        .custom-control-inline {
            margin-right: 0.7rem;
        }
    }

    .form-group {
        margin-bottom: 1.5rem;
        ;

        &:last-of-type {
            margin-bottom: 0;
        }

        ::v-deep legend {
            font-weight: 500;
        }
    }
}

.mark-active {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, .5);
}

.ps-customizer-area {
    height: calc(100% - 83px)
}
</style>
